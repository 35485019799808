<template>
  <div>
    <v-btn
      color="#38227A"
      dark
      class="text-capitalize px-7 mr-5"
      @click="newCampaign()"
      height="45px"
      style="border-radius: 10px; margin-left: 15px"
    >
      <span>Back to list</span>
    </v-btn>
    <div class="pt-5">
      <b><hr style="margin-bottom: 5px" /></b>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card-text>
          <v-form ref="schoolForm" style="margin-top: 40px">
            <v-row no-gutters justify="center">
              <v-col cols="8" class="px-2">
                <label>School</label>
                <v-text-field
                  outlined
                  dense
                  color="#7253CF"
                  class="formFields"
                  v-model="school"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="4" class="px-2">
                <label>Address</label>
                <v-text-field
                  outlined
                  dense
                  v-model="address"
                  color="#7253CF"
                  class="formFields"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="px-2">
                <label>Name</label>
                <v-text-field
                  outlined
                  dense
                  color="#7253CF"
                  class="formFields"
                  v-model="name"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="4" class="px-2">
                <label>Assembly Date</label>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      prepend-inner-icon="mdi-calendar-range"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      v-on="on"
                      v-model="formatedDate"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="date"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="px-2">
                <label>Mobile Number</label>
                <v-text-field
                  outlined
                  dense
                  v-model="mobile"
                  color="#7253CF"
                  class="formFields"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row no-gutters justify="center">
              <v-col cols="4" class="px-2">
                <v-autocomplete
                  label="Team"
                  outlined
                  dense
                  v-model="team"
                  :items="teamNameList"
                  class="formFields"
                  item-text="team_name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col  cols="4" v-if="this.team == null">
                <v-combobox
                  v-model="players"
                  :items="playerList"
                  label="Player"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                  multiple
                  outlined
                  dense
                ></v-combobox>
              </v-col>
              </v-row>-->
            <v-row no-gutters justify="center">
              <v-col cols="4" class="pl-2">
                <label style="font-size: 16px;">Start Time</label>
                   <br>
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  placeholder="Start Time"
                  input-width="530px"
                  auto-scroll
                  v-model="start_time"
                  close-on-complete
                ></vue-timepicker>
              </v-col>
              <v-col cols="4" class="pl-2">
                <label style="font-size: 16px;">End Time</label>
                 <br>
                <vue-timepicker
                  format="hh:mm A"
                  class="formFields"
                  placeholder="End Time"
                  input-width="530px"
                  auto-scroll
                  v-model="end_time"
                  close-on-complete
                ></vue-timepicker>
              </v-col>
            </v-row>
            <br />
            <v-row no-gutters justify="center">
              <v-col cols="4" class="px-2">
                <label>Number Of Student</label>
                <v-text-field
                  outlined
                  dense
                  v-model="noOfStudents"
                  type="number"
                  color="#7253CF"
                  class="formFields"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="px-2">
                <label>Number Of Assembly</label>
                <v-text-field
                  outlined
                  dense
                  v-model="noOfAssemblies"
                  type="number"
                  color="#7253CF"
                  class="formFields"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="8" class="px-2">
                <quillEditor v-model="notes" :options="editorOption" />
              </v-col>
            </v-row>
            <br />
            <v-row no-gutters justify="end">
              <v-btn
                class="text-capitalize px-7 mr-5"
                :loading="formLoading"
                dark
                color="#38227A"
                @click="addSchool"
                >Add more school</v-btn
              >
              <br />
            </v-row>
            <div v-for="(school, index) in schools" :key="index">
              <h3 style="margin-left: 240px">School #{{ index + 1 }}</h3>
              <v-icon
                size="18"
                color="red"
                @click="removeSchool(index)"
                style="margin-left: 1400px"
                >mdi-delete</v-icon
              >
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <label>Driver Name</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="name"
                    color="#7253CF"
                    class="formFields"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>Driver Cell</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="mobile"
                    color="#7253CF"
                    class="formFields"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <label>Name of School</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school.name"
                    color="#7253CF"
                    class="formFields"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>Time</label>
                  <vue-timepicker
                    format="hh:mm A"
                    v-model="school.time"
                    class="formFields"
                    placeholder=" Time"
                    input-width="530px"
                    auto-scroll
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <label>Address</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school.address"
                    color="#7253CF"
                    class="formFields"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>Location within school</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school.location"
                    color="#7253CF"
                    class="formFields"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <label>Email Address Of Principal</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school.email"
                    color="#7253CF"
                    class="formFields"
                    :rules="[rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>Phone</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school.phone"
                    color="#7253CF"
                    class="formFields"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <label>Number Of Student</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school.no_of_student"
                    type="number"
                    color="#7253CF"
                    class="formFields"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>Grades</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school.grade"
                    type="number"
                    color="#7253CF"
                    class="formFields"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <label>Assembly</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school.assembly"
                    color="#7253CF"
                    class="formFields"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- Other fields... -->
            </div>
          </v-form>
        </v-card-text>
      </v-col>
    </v-row>

    <v-row no-gutters justify="center" style="margin-top: 40px">
      <v-btn
        class="submit-btn"
        dark
        color="#38227A"
        @click="
          saveForm();
          submitForm();
        "
        >Submit</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import { ROUTER_URL } from "@/constants/urls";
import moment from "moment";
import Helper from "@/helper";
import Axios from "@/api/BaseAxios";
import {
  ASSEMBLY_SCHEDUAL_API_POST,
  ASSEMBLY_SCHEDUAL_API_PATCH,
  ASSEMBLY_SCHEDUAL_API_GET,
} from "@/constants/APIUrls";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import RULES from "@/common/fieldRules";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddEditViewSchoolModal",
  components: { quillEditor, VueTimepicker },
  data() {
    return {
      selected: 0,
      tabs: [
        { name: "Assembly Schedule", value: "assembly" },
        { name: "Hotel", value: "hotel" },
      ],
      rules: RULES,
      menu1: false,
      menu2: false,
      menu3: false,
      loading: false,
      formLoading: false,
      school: "",
      schoolList: [],
      address: "",
      date: "",
      start_time: "08:00 AM",
      end_time: "08:00 AM",
      noOfStudents: null,
      noOfAssemblies: null,
      name: "",
      mobile: null,
      email: "",
      notes: "",
      players: [],
      playerList: [],
      team_name: "",
      team_id: "",
      team: "",
      lisssst: [{ id: null, team_name: "unassigned" }],
      demoTeamList: [this.lisssst + this.teamNameList],
      teamNameList: [],
      schools: [
        {
          time: "",
          name: "",
          // Other fields...
        },
      ],
      editorOption: {
        placeholder: "Message Content",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            [{ direction: "rtl" }], // text direction
            ["clean"],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getShow: "gameManagement/getAssembly",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.toggleAssemblyModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.addEditAssemblyModal.type;
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add new Assembly Schedule";
        case "edit":
          return "Update Assembly Schedule";
        default:
          return "";
      }
    },
    formatedDate: {
      get() {
        if (this.date) {
          return moment(this.date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
    },
    formattedTime: {
      get() {
        if (this.start_time) {
          return Helper.timeFormatter(this.start_time);
        } else {
          return "";
        }
      },
    },
    formattedEndTime: {
      get() {
        if (this.end_time) {
          return Helper.timeFormatter(this.end_time);
        } else {
          return "";
        }
      },
    },
    tabsSelectedValue() {
      return this.tabs[this.selected].value;
    },
  },
  methods: {
    ...mapActions({
      toggleAssemblyModal: "gameManagement/toggleAssembly",
      showToast: "snackBar/showToast",
    }),
    getDetail() {
      const successHandler = (res) => {
        console.log(res);
        this.school = res.data.assemble_detail.school;
        this.address = res.data.assemble_detail.address;
        this.date = res.data.assemble_detail.date;
        this.start_time = Helper.convertFrom24To12Format(
          res.data.assemble_detail.start_time_str
        );
        this.end_time = Helper.convertFrom24To12Format(
          res.data.assemble_detail.end_time_str
        );
        this.noOfStudents = res.data.assemble_detail.total_student;
        this.noOfAssemblies = res.data.assemble_detail.total_assemblies;
        this.name = res.data.assemble_detail.driver_name;
        this.mobile = res.data.assemble_detail.phone;
        this.email = res.data.assemble_detail.email;
        this.notes = res.data.assemble_detail.note;
        this.team = res.data.assemble_detail.team;
        if (res.data.assemble_detail.player_list != null) {
          let playerIdList = res.data.assemble_detail.player_list.map(function (
            obj
          ) {
            return obj.id;
          });
          this.players = this.playerList.filter((k) =>
            playerIdList.includes(k.id)
          );
        }
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["assemble_id"] = 139;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      // alert("dsdas");
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        this.$emit("reload");
        this.toggleAssemblyModal({ show: false });

        this.formLoading = false;
        location.reload();
      };
      const failureHandler = (res) => {
        console.log(res);

        this.formLoading = false;
      };
      let formData = {};
      formData["game"] = this.$route.query.game_id;
      if (this.school) {
        formData["school"] = this.school;
      }
      if (this.address) {
        formData["address"] = this.address;
      }
      if (this.formatedDate) {
        formData["date"] = this.formatedDate;
      }
      if (this.start_time) {
        formData["start_time"] = Helper.convertTime12To24(this.start_time);
      }
      if (this.end_time) {
        formData["end_time"] = Helper.convertTime12To24(this.end_time);
      }
      if (this.noOfStudents) {
        formData["total_student"] = this.noOfStudents;
      }
      if (this.noOfAssemblies) {
        formData["total_assemblies"] = this.noOfAssemblies;
      }
      if (this.name) {
        formData["driver_name"] = this.name;
      }
      if (this.mobile) {
        formData["phone"] = this.mobile;
      }
      if (this.email) {
        formData["email"] = this.email;
      }
      if (this.notes) {
        formData["note"] = this.notes;
      }
      this.playersCopy = [];
      Object.keys(this.players).forEach((key) =>
        this.playersCopy.push(this.players[key].id)
      );
      if (this.team) {
        formData["team"] = this.team;
        formData["players"] = [];
      } else {
        formData["team"] = null;
        formData["players"] = this.playerCopy;
      }
      if (!formData["id"]) {
        Axios.request_POST(
          ASSEMBLY_SCHEDUAL_API_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        formData["id"] = 139;
        Axios.request_PATCH(
          ASSEMBLY_SCHEDUAL_API_PATCH,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    addSchool() {
      this.schools.push({
        //  driver_name: "",
        //  driver_cell: "",
        name: "",
        time: "",
        address: "",
        phone: "",
        location: "",
        email: "",
        no_of_student: "",
        assembly: "",
        grade: "",

        // Initialize other fields...
      });
    },
    removeSchool(index) {
      this.schools.splice(index, 1);
    },
    saveForm() {
      // alert("dsdsa");
      const formattedData = this.schools
        .map((school, index) => {
          return `
    
<b>School #${index + 1}</b><br>
<b>Name of school:</b> ${school.name}<br>
<b>Time:</b> ${school.time}<br>
<b>Address:</b> ${school.address}<br>
<b>Phone #:</b> ${school.phone}<br>
<b>Email address of principal or contact at school:</b> ${school.email}<br>
<b>Number of Students:</b> ${school.no_of_student}<br>
<b>Grades:</b> ${school.grade}<br>
<b>Location within School:</b> ${school.location}<br>
<b>Assembly or Cafeteria visit?:</b> ${school.assembly}<br>
        `;
        })
        .join("\n\n");

      // Now, you can save 'formattedData' to your 'notes' field or perform any other action.
      console.log(formattedData);
      this.notes = formattedData;
      console.log("this.notes", this.notes);
    },
    newCampaign() {
      // this.$root.$refs.adminLayout.route("campaign")
      this.$router.push({
        name: ROUTER_URL.gamePanel.children.gamehostassembly.name,
        query: { type: "add" },
      });
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>
