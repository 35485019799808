<template>
  <div class="px-7 py-7">
  <v-row>
  <v-btn
          color="#38227A"
          dark
          class="text-capitalize px-7 mr-5"
          height="40px"
          style="border-radius: 10px; margin: 20px 5px"
         @click="toggleVolunteerModal({ show: true, type: 'add' })"
        >
          <span>Add Committee</span>
        </v-btn>
  </v-row>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="items"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
      :items-per-page="page_info.detail_count"
      :server-items-length="page_info.detail_count"
      :options.sync="dataOptions"
    >
      <!-- items template -->
        <template v-slot:item="props" >
        <tr>
          <td class="text-center">
            <div>{{ props.item.name }}</div>
          </td>
          <td class="text-center">
            <div>
              {{ props.item.email }}
            </div>
          </td>
          <td class="text-center">
            <div>
              {{ props.item.phone }}
            </div>
          </td>
          <td class="text-center">
            <div>
              {{ props.item.game_designation }}
            </div>
          </td>
         <td class="text-center py-4">
           <!-- <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="
                toggleVolunteerModal({
                  show: true,
                  type: 'edit',
                  id: props.item.id,
                })
              "
          >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>-->
            <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="toggleDeleteModal({ show: true, id: props.item.committee_id })"
         >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="22"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
              <v-chip
              class="actionColumn-btn"
              color="#F2F2F2"
              @click="resendInvitation(props.item)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <span>Resend Invitation</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
      <!-- items template -->
    <!--  <template v-slot:footer>
        <div>
          <v-divider></v-divider>
          <v-row no-gutters class="pt-6 px-2">
            <v-col
              class="mb-4"
              align-self="center"
              cols="12"
              xs="12"
              sm="6"
              md="4"
            >
              <div
                class="justify-center justify-md-start d-flex px-5 tableHeader-text"
              >
                <div class="alignSelf-center mr-3">Show</div>
                <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                  <v-select
                    class=""
                    :items="itemsPerPageList"
                    hide-details
                    v-model="page_info.detail_count"
                    dense
                  ></v-select>
                </div>

                <div class="alignSelf-center">
                  {{ page_info.page_number }}
                  of
                  {{ page_info.total_page }}
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" md="4">
              <div>
                <v-pagination
                  :length="page_info.total_page"
                  v-model="page_info.page_number"
                  :total-visible="5"
                  color="#D30024"
                  class="pagination"
                >
                </v-pagination>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>-->
    </v-data-table>
      <volunteer-modal @reload="getVolunteersData()"></volunteer-modal>
    <delete-modal @reload="deleteVolunteersData()"></delete-modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import {
  //   DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  //   DATA,
} from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import {
   API_GAME_COMMITTEE_LIST,
   API_GAME_COMMITTEE_DELETE
} from "@/constants/APIUrls";
import _ from "lodash";
export default {
  name: "GameTable",
  components: {
    DeleteModal: () => import("../DeleteModal/DeleteModal"),
    VolunteerModal: () => import("../GameDashboardVASH/VolunteerModal/VolunteerModal"),
  },
  data() {
    return {
      yearList: [],
      yearFilter: "",
      showFlag: false,
      currentState: false,
      adminAccess: localStorage.getItem("adminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      items: [],
      dates: [],
      menu: false,
      start_date: "",
      end_date: "",
      loading: false,
      [PAGE_INFO]: {
        [PAGE_NUMBER]: 1,
        [TOTAL_PAGE]: 5,
        [ITEMS_PER_PAGE]: 10,
      },
      itemsPerPageList: [10, 15, 20, 30, 40, 50, 100],
      [SEARCH]: "",
      [SORTING_KEY]: "",
     headers: [
        {
          sortable: false,
          text: "Name",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Email",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Mobile",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Designation",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
         {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
    };
  },
  computed: {
    dateRangeText: {
      get() {
        if (this.dates) {
          if (this.dates.length > 1) {
            let date = [
              moment(this.dates[0]).format("MM-DD-YYYY"),
              moment(this.dates[1]).format("MM-DD-YYYY"),
            ];
            return date.join(" - ");
          } else {
            return "";
          }
        } else {
          return "";
        }
        // return this.dates.join("  -  ");
      },
      set(newDates) {
        this.dates = newDates || [];
        return this.dates.length > 1 ? this.dates.join("  -  ") : "";
      },
    },
  },
  watch: {
    dates: function () {
      if (this.dates.length !== 1) {
        this.debounceGetGameManagementData();
      }
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.sorting_key = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getVolunteersData();
      } else {
        this.sorting_key = "";
        this.getVolunteersData();
      }
    },
    search() {
      this.debounceGetGameManagementData();
    },
    "page_info.page_number": function () {
      this.getVolunteersData();
    },
    "page_info.detail_count": function () {
      this.getVolunteersData();
    },
  },
  methods: {
    ...mapActions({
        toggleDeleteModal: "deleteManagement/toggleModal",
        toggleVolunteerModal: "gameManagement/toggleVolunteer",
      showToast: "snackBar/showToast",
    }),

 
    filterDate(dates) {
      if (dates.length > 1) {
        this.$refs.menu.save(dates);
      }
    },
      getVolunteersData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.team_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
       formData[PAGE_NUMBER] = this.page_info[PAGE_NUMBER];
      formData[ITEMS_PER_PAGE] = this.page_info.detail_count;
      Axios.request_GET(
        API_GAME_COMMITTEE_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
       resendInvitation(item) {
      const successHandler = () => {
        this.showToast({
          message: "Invitation sent",
          color: "s",
        });
      };

      const failureHandler = () => {};
      let formData = {};
     formData["user_id"] = item.id;
      formData["id"]=this.$route.query.game_id;
      Axios.request_GET(
        "/game/resend_email/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
 deleteVolunteersData() {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted Successfully",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getVolunteersData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        API_GAME_COMMITTEE_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
   
  
  },
  mounted() {
    this.debounceGetGameManagementData = _.debounce(this.getVolunteersData, 500);
  },
  created() {},
};
</script>
<style scoped>
.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
}
.alignSelf-center {
  align-self: center;
}
* {
  font-family: Lato;
}
.v-btn >>> span {
  letter-spacing: 0px;
  font-weight: 600;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}
.textHeading {
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
}
.textHeadinglabels {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
