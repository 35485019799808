<style>
.v-application .white--text {
  color: #000 !important;
  caret-color: #000 !important;
  font-weight: bolder;
}

.v-card__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  background: #fff;
}

.students {
  display: flex;
  list-style: none;
  padding: 0;
}
.student ~ .student::before {
  content: ", ";
}
.theme--dark.v-sheet {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
  color: black;
}
.v-calendar .v-event {
  white-space: unset !important;
  overflow-wrap: break-word;
}
.v-calendar .v-event-summary {
    white-space: unset !important;
    overflow-wrap: break-word;
}
.td-container {
  display: flex;
  justify-content: space-between;
  margin: 10px; /* Adjust the margin as needed */
}
@media (max-width: 1281px) {
.v-calendar .v-event {
line-height: 10px  !important;;
}
}
@media (max-width: 1281px) {
.v-event.v-event-start.v-event-end.white--text {
  height: auto !important;
  font-size:5px !important;
}
}
@media (min-width: 1281px) {
.v-event.v-event-start.v-event-end.white--text {
  height: auto !important;
  font-size:12px !important;
}
}
@media (max-width: 1281px) {
  .v-calendar-monthly.v-calendar-weekly.v-calendar.theme--light.v-calendar-events {
    width: 168% !important;
  }
}
@media (min-width: 960px) {
  .v-toolbar__title {
    margin: 25px;
  }
}

@media (max-width: 1281px) {
  .v-application .px-2 {
    padding-left: 0px !important;
  }
}
.column-container {
  column-count: 3; /* Set the number of columns */
  column-gap: 10px; /* Adjust the gap between columns as needed */
  max-width: 600px; /* Adjust the max width of the container as needed */
}
</style>
<template>
  <v-app>
    <div>
     <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-sheet
        tile
        class="col-md-4 col-lg-4 col-sm-12 col-xs-12"
        style="text-align: center"
      >
        <!-- Dropdown for Month, Day, Week, List -->
       
      </v-sheet>
      <v-sheet
        tile
        class="col-md-4 col-lg-3 col-sm-12 col-xs-12 text-center"
        style="padding: 1px"
      >
        <v-toolbar-title v-if="$refs.calendar">
          <v-icon
            @click="$refs.calendar.prev()"
            style="color: #38227a; cursor: pointer"
            >mdi-chevron-left</v-icon
          >
          <b>{{ $refs.calendar.title }}</b>
          <v-icon
            @click="$refs.calendar.next()"
            style="color: #38227a; cursor: pointer"
            >mdi-chevron-right</v-icon
          >
        </v-toolbar-title>
      </v-sheet>
    </v-row>

    
     <v-row v-if="$vuetify.breakpoint.xsOnly">
      
      <v-sheet
        tile
        class="col-md-4 col-lg-3 col-sm-12 col-xs-12 text-center"
        style="padding: 1px;margin-top: 3%;"
      >
        <v-toolbar-title v-if="$refs.calendar">
          <v-icon
            @click="$refs.calendar.prev()"
            style="color: #38227a; cursor: pointer; font-size: 14px"
            >mdi-chevron-left</v-icon
          >
          <b style="font-size: 14px">{{ $refs.calendar.title }}</b>
          <v-icon
            @click="$refs.calendar.next()"
            style="color: #38227a; cursor: pointer; font-size: 14px"
            >mdi-chevron-right</v-icon
          >
        </v-toolbar-title>
      </v-sheet>
    </v-row>
      <div class="ma-4" style="height: 50%">
          <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card min-width="auto" style="height: 400px; background: #ffffff">
          <v-toolbar
            dark
            color="#ffffff"
            v-if="this.selectedEvent.eventids == 1"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
              <v-icon
                text
                size="27"
                color="white"
                style="cursor: pointer; position: relative"
                @click="getMonthCalendarData()"
              >
              </v-icon>
            </v-toolbar-title>
          </v-toolbar>
          <v-toolbar
            color="#edc967a1"
            dark
            v-if="this.selectedEvent.color == '#edc967a1'"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
          </v-toolbar>
          <v-toolbar
            color="#ffffff"
            dark
            v-if="this.selectedEvent.color == '#E56E94 '"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
          </v-toolbar>
          <v-toolbar
            color="#ffffff"
            dark
            v-if="this.selectedEvent.color == '#41a7f5ad'"
          >
            <v-toolbar-title>
              {{ selectedEvent.name }}
            </v-toolbar-title>
          </v-toolbar>
          <game-modal></game-modal>
          <assembly-schedual-modal></assembly-schedual-modal>
          <v-card-text>
            <div
              class="text-center"
              v-if="this.selectedEvent.eventids == 1"
              style="cursor: pointer"
            >
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
              <div
                style="
                  text-align: justify;
                  font-size: 16px;
                  text-decoration: underline;
                "
              >
                <strong v-if="this.adminAccess == 'Player'"
                  >{{ this.gameData.game_date_str }},
                  {{ this.gameData.game_time_str }}-{{
                    converTime(this.gameData.end_time)
                  }}
                  | {{ this.gameData.game_timezone }}</strong
                >
                <strong @click="visitGamePanelHandler(selectedEvent.id)" v-else
                  >{{ this.gameData.game_date_str }},
                  {{ this.gameData.game_time_str }}-{{
                    converTime(this.gameData.end_time)
                  }}
                  | {{ this.gameData.game_timezone }}</strong
                >
                &nbsp;&nbsp;&nbsp;
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAddEditViewGameModal({
                      show: true,
                      type: 'edit',
                      dashboard: true,
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
              <div style="text-align: justify; font-size: 16px">
                <strong>Game City and State: </strong
                >{{ this.gameData.city }},{{ this.gameData.state_name }}
                <br />
                <br />
                   <strong style="color: red">Ticket Sold Info: </strong
                >{{ this.gameData.total_online_ticket_sold }} /
                {{ this.gameData.total_seat }}
                <br />
                <br />
               <!-- <strong style="color: red">Presale Ticket: </strong
                >{{ this.gameData.total_ticket_sold }} as of
                {{ this.gameData.presale_date_str }}
                <br />
                <br />-->
                <strong style="font-size: 20px; text-decoration: underline"
                  >Previous Event Info:</strong
                >
                <br />
                <strong>Year: </strong>2023
                <br />
                <strong>Ticket Sale: </strong
                >{{ this.gameData.total_ticket_sold }}
                <br />
                <strong>Team: </strong>{{ this.gameData.team_name }}
                <br />
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:
                </strong>
                <br />
                <strong>Host Name: </strong
                >{{ this.gameData.host_name }} &nbsp;&nbsp;&nbsp;
                <br />
                <strong>Cell Phone: </strong>{{ this.gameData.host_phone }}
                <br />
                <strong>Email: </strong>{{ this.gameData.host_email }}
                <br />
                <strong>Sales Rep: </strong>{{ this.gameData.sales_rep_name }}
                <br />
                <strong>Event Support Wizard: </strong
                >{{ this.gameData.event_support_staff }}
                <br />
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >General Details:
                </strong>
                <br />
                <strong>Host Group Name: </strong>Host Group Name
                <br />
                <strong>Game Date & Time: </strong
                >{{ this.gameData.game_date_str }}
                {{ converTime(this.gameData.start_time) }}-{{
                  converTime(this.gameData.end_time)
                }}
                <br />
                <strong>Arrival Time: </strong
                >{{this.gameData.arrival_time_str}}
                <br />
                <strong>Site: </strong>{{ this.gameData.event_location_name }}
                <br />
                <strong> Event Address: </strong
                >{{ this.gameData.event_location }}
                <br />
                <strong>Seating Capacity: </strong>-
                <br />
                <strong>Contract Type: </strong
                >{{ this.gameData.contract_type }}

                <br />
              <strong>Note: </strong>
                <div v-html="this.gameData.notes"></div>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div v-if="this.gameData.ticket_data != null">
                  <strong style="font-size: 20px; text-decoration: underline"
                    >Ticket Prices:
                  </strong>
                  <v-col cols="12">
                    <div class="td-container">
                      <td>
                        <label>Name</label>
                        <tr
                          v-for="(items, i) in tickettypeitems"
                          :key="i"
                          v-text="items.item_name"
                        ></tr>
                      </td>

                      <td>
                        <label>ADV</label>
                        <tr
                          v-for="(items, p) in tickettypeitems"
                          :key="p"
                          v-text="items.default_price"
                        ></tr>
                      </td>

                      <td>
                        <label>Door</label>
                        <tr
                          v-for="(items, p) in tickettypeitems"
                          :key="p"
                          v-text="items.door_price"
                        ></tr>
                      </td>
                    </div>
                  </v-col>
                </div>
                <br />
                <br />
                <br />
                <div v-if="this.gameData.hotel_data != null">
                  <strong style="font-size: 20px; text-decoration: underline"
                    >Hotel-
                  </strong>
                  <br />
                  <strong>Name: </strong>{{ this.gamehotalData.name }}
                  <br />
                  <strong>Address: </strong
                  >{{ this.gamehotalData.hotel_address }}
                  <br />
                  <strong>Email: </strong>{{ this.gamehotalData.contact_email }}
                  <br />
                  <strong>Confirmation: </strong
                  >{{ this.gamehotalData.confirmation_number }}
                  <br />
                  <strong>Reservation Under: </strong
                  >{{ this.gamehotalData.contact_person_name }}
                </div>
                <br />
                <br />
                <br />
                <div v-if="this.gameData.assemble_data_sameday != null">
                  <strong style="font-size: 16px; text-decoration: underline">
                    FREE ASSEMBLY SCHEDULE (if on Game Day):
                  </strong>
                  <br />

                  <strong>Event city: </strong
                  >{{ this.gameData.assemble_data_sameday[0].assemble_city }}
                  <br />
                  <strong> contact name:</strong
                  >{{ this.gameData.assemble_data_sameday[0].host_name }}
                  <br />
                  <strong>Driver name: </strong
                  >{{
                    this.gameData.assemble_data_sameday[0].organization_name
                  }}
                  <br />
                  <strong>School 1: </strong
                  >{{ this.gameData.assemble_data_sameday[0].school }} <br />
                </div>
                <br />
                <br />
              </div>
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </div>

            <div class="text-center" v-if="this.selectedEvent.eventids == 3">
              <hr
                style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;#41a7f5ad'
                  "
              />
              <div style="text-align: justify; font-size: 16px">
                <strong
                  >{{ this.assemblyData.assemble_date_str }},
                  {{ this.assemblyData.start_time_str }}-{{
                    this.assemblyData.end_time_str
                  }}
                  | {{ this.assemblyData.assemble_timezone }}</strong
                >&nbsp;&nbsp;&nbsp;
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAssemblyModal({
                      show: true,
                      type: 'edit',
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />

                <br />
                <div v-if="this.assemblyData.player_list != null">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in playeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                </div>
                <strong style="font-size: 20px; text-decoration: underline"
                  >Info:
                </strong>
                <br />
                <strong>School: </strong>{{ this.assemblyData.school }}
                <br />
                <strong>Address: </strong>{{ this.assemblyData.address }}
                <br />
                <br />
                <strong>No.Of Assemblies: </strong
                >{{ this.assemblyData.total_assemblies }}
                <br />
                <strong>No. Of Student: </strong
                >{{ this.assemblyData.total_student }}
                <br />
                <br />
                <div>
                  <v-col cols="8">
                    <strong style="margin-left: -11px">Description: </strong>
                    <div v-html="this.assemblyData.note"></div>
                  </v-col>
                </div>
                <br />
                <strong>Contact: </strong>{{ this.assemblyData.host_name }}
                <br />
                <strong>Phone No.: </strong>{{ this.assemblyData.phone }}

                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Game Info:
                </strong>
                <br />
                <strong>Game City and State: </strong
                >{{ this.assemblyData.assemble_city }},{{
                  this.assemblyData.assemble_state
                }}
                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Previous Event Info:</strong
                >
                <br />
                <strong>Year: </strong>2023
                <br />
                <strong>Ticket Sale: </strong>
                <br />
                <strong>Team: </strong
                >{{ this.assemblyData.assemble_team_name }}

                <br />
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >General Details:
                </strong>
                <br />
                <strong>Team Doing Game: </strong>
                {{ this.assemblyData.assemble_team_name }}
                <br />
                <strong>Host Group Name: </strong
                >{{ this.assemblyData.organization_name }}
                <br />
                <strong>Game Date And Time: </strong
                >{{ this.assemblyData.game_date }} -
                {{ converTime(this.assemblyData.game_starttime) }},
                <br />
                <strong>Site: </strong
                >{{ this.assemblyData.event_location_name }}
                <br />
                <strong> Event Address: </strong
                >{{ this.assemblyData.event_location }}
                <br />
                <strong>Seating Capacity: </strong>
                <br />
                <strong>Contract Type: </strong
                >{{ this.assemblyData.contract_type }}
                <br />
                <strong> Repeat game (Y/N?): </strong>
                <br />
                <strong> Wiz Kid? Yes </strong>
                <br />
                <br />
                  <div v-if="this.assemblyData.ticket_data != null">
                  <strong style="font-size: 20px; text-decoration: underline"
                    >Ticket Prices:
                  </strong>
                  <v-col cols="12">
                    <div class="td-container">
                      <td>
                        <label>Name</label>
                        <tr
                          v-for="(items, i) in ticketassemblyitems"
                          :key="i"
                          v-text="items.item_name"
                        ></tr>
                      </td>
                      <td>
                        <label>ADV</label>
                        <tr
                          v-for="(items, p) in ticketassemblyitems"
                          :key="p"
                          v-text="items.default_price"
                        ></tr>
                      </td>
                      <td>
                        <label>Door</label>
                        <tr
                          v-for="(items, p) in ticketassemblyitems"
                          :key="p"
                          v-text="items.door_price"
                        ></tr>
                      </td>
                    </div>
                  </v-col>
                </div>
                <br />
                <strong style="font-size: 20px; text-decoration: underline"
                  >Contact Info:
                </strong>
                <br />
                <strong>Host Name: </strong
                >{{ this.assemblyData.host_name }} &nbsp;&nbsp;&nbsp;
                <br />
                <strong>Cell Phone: </strong>{{ this.assemblyData.host_phone }}
                <br />
                <strong>Email: </strong>{{ this.assemblyData.host_email }}
                <br />
                <strong>Sales Rep: </strong
                >{{ this.assemblyData.sales_rep_name }}
                <br />
                <strong>Event Support Wizard: </strong
                >{{ this.assemblyData.event_support_staff }}
                <br />
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>
            <div class="text-center" v-if="this.selectedEvent.eventids == 4">
              <div style="text-align: justify; font-size: 16px">
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.hotelData.checking_in_date_str }} |
                {{ this.hotelData.hotel_timezone }}
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleHotelInfoModal({
                      show: true,
                      type: 'edit',
                      id: selectedEvent.id,
                    })
                  "
                  v-if="this.adminAccess != 'Player'"
                >
                  mdi-pencil
                </v-icon>
                <br />
                <br />
                <strong>Check in date: </strong
                >{{ this.hotelData.checking_in_date_str }} &nbsp;
                <strong>Check out date: </strong
                >{{ this.hotelData.checking_in_date_str }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <div v-if="this.hotelData.player_list != null">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in hotelplayeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                </div>
                <br />
                <strong>Category: </strong>Hotel
                <br />
                <br />
                <strong>Description: </strong> {{ this.hotelData.note }}
                <br />
                <br />
                <strong>Hotel Name: </strong> {{ this.hotelData.name }}
                <br />
                <strong>Hotel Address: </strong>
                {{ this.hotelData.hotel_address }}
                <br />
                <strong>Contact Person Name: </strong>
                {{ this.hotelData.contact_person_name }}
                <br />
                <strong>Contact Person Email: </strong>
                {{ this.hotelData.contact_email }}
                <br />
                <strong>Confirmation Number: </strong>
                {{ this.hotelData.confirmation_number }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>
            <!-- <div class="text-center" v-if="this.selectedEvent.eventids == 3">
              <div style="text-align: justify; font-size: 16px">
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                <strong
                  >{{ this.assemblyData.assemble_date_str }},
                  {{ converTime(this.assemblyData.start_time) }}-{{
                    converTime(this.assemblyData.end_time)
                  }}
                  | {{ this.assemblyData.assemble_timezone }}</strong
                >&nbsp;&nbsp;&nbsp;
                <v-icon
                  dark
                  class="icons"
                  color="#6B6B6B"
                  size="22"
                  @click="
                    toggleAddEventModal({
                      show: true,
                      type: 'edit',
                      id: selectedEvent.id,
                    })
                  "
               v-if="this.adminAccess!='Player'" >
                  mdi-pencil
                </v-icon>
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
                <br />
                <div v-if="this.assemblyData.player_list != null">
                  <tr class="students">
                    <strong>Players: </strong>
                    <td
                      v-for="(item, i) in playeritems"
                      :key="i"
                      v-text="item.name"
                      class="student show"
                    ></td>
                  </tr>
                </div>
                <br />
                <strong>Category: </strong>Paid Assembly
                <br />
                <br />
                <strong>Description: </strong>{{ this.assemblyData.note }}
                <br />
                <br />
                <strong>school: </strong>{{ this.assemblyData.school }}
                <br />
                <strong>Address: </strong>{{ this.assemblyData.address }}
                <br />
                <strong>Organization: </strong
                >{{ this.assemblyData.organization }}
                <br />
                <strong>Designation: </strong
                >{{ this.assemblyData.designation }}
                <br />
                <strong>Event Support: </strong
                >{{ this.assemblyData.event_support }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>-->
          </v-card-text>
        </v-card>
      </v-menu>
        <img
          src="@/assets/thingsToDo/loader5.gif"
          width="100"
          height="100"
          style="margin-left: 740px"
          v-if="loading"
        />
        <v-calendar
          ref="calendar"
          color="primary"
          :events="events"
          v-model="value"
          type="month"
          :weekdays="weekday"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          @click:event="showEvent"
          @change="updateRange"
          style="height: auto"
        ></v-calendar>
        <div class="column-container">
        <ul>
          <li style="color: #ffcccb">Games</li>
          <li style="color: #E56E94">Hotel</li>
        </ul>
        <ul>
          <li style="color: #41a7f5">Assembly</li>
          <li style="color: #a9a9a9">Unassigned</li>
        </ul>
    </div>
      </div>
    </div>
     <HotelInfoModal></HotelInfoModal>
  </v-app>
</template>
<script>
import { GAME_CALENDAR_API, ADMIN_GAMES_GET_DETAIL,
  ASSEMBLY_SCHEDUAL_API_GET,
  GAME_HOTEL_GET_API, } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "App",
  components: { GameModal: () =>
      import("../AddEditGameModal/AddEditGameModal"),
    HotelInfoModal: () =>
      import("../HotelInfoModal/HotelInfoModal"),
    AssemblySchedualModal: () =>
      import(
        "../GameDashboardVASH/AssemblySchedualModal/AssemblySchedualModal"
      ),},
  data: () => {
    return {
      focus: "",
      events: [],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },

        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      colors: ["blue", "black", "orange"],
      today: new Date(),
      firstcalldate: "",
      selectedOpen: false,
      selectedElement: undefined,
      selectedEvent: {},
      eventcategoryid: localStorage.getItem("eventid"),
      startdatefmin: localStorage.getItem("startdatefilter"),
      gameData: {},
      gamedisplaydate: "",
      assemblydisplaydatess: "",
      assemblyData: {},
      hotelData: {},
      hoteldisplaydatess: "",
      loading: false,
    };
  },
  mounted() {
    //this.$refs.calendar.move(`2023-01-21`)
   // this.$refs.calendar.move(1);
    //this.getMonthCalendarData();
  },
  created() {
    // this.$root.$refs.gamecalendar = this;
  },
  methods: {
    ...mapActions({
      // calendar Modal
      toggleAddEditViewGameModal: "gameManagement/toggleModal",
      toggleAssemblyModal: "gameManagement/toggleAssembly",
      toggleHotelInfoModal: "gameManagement/toggleHotelInfoModal",
      showToast: "snackBar/showToast",
    }),

    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    updateRange({ start, end }) {
      const min = new Date(`${start.date}T24:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      this.firstdatemin = moment(min).format("YYYY-MM-DD");
      this.lastdatemin = moment(max).format("YYYY-MM-DD");
      this.startMonth = this.$refs.calendar.title;
      this.getMonthCalendarData();
    },
    getMonthCalendarData() {
      this.events = [];
      const self = this;
      self.loading = true;
      console.log("in mounted");
      const successHandler = (res) => {
        this.eventss = res.data.calendar_list;
        for (const items of this.eventss) {
          for (var a = 0; a < this.eventss.length; a++) {
            for (const itemsasem of this.eventss[a].assemblies) {
              if (items.assemble_id == itemsasem.id) {
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name != null &&
                  itemsasem.player_list == null
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    "," +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.team_name +
                    ")";
                  const startdate1 = items.start_date;
                  const time = items.start_time;
                  var dateTime11 = startdate1 + " " + time;
                  const start = dateTime11;
                  const color = "#41a7f5ad";
                  const id = items.assemble_id;
                  const eventids="3"
                  this.events.push({ name, start, color, eventids,id });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list != null
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    "," +
                    itemsasem.assemble_state +
                    "-FA" +
                    "(" +
                    itemsasem.player_list.map((player) => player.name)+
                    ")";
                  const startdate1 = items.start_date;
                  const time = items.start_time;
                  var dateTime12 = startdate1 + " " + time;
                  const start = dateTime12;
                  const color = "#41a7f5ad";
                   const eventids="3"
                  const id = items.assemble_id;
                  this.events.push({ name, start, color,eventids, id });
                }
                if (
                  items.event_category == 3 &&
                  itemsasem.team_name == null &&
                  itemsasem.player_list == null
                ) {
                  const name =
                    itemsasem.assemble_city.toUpperCase() +
                    "," +
                    itemsasem.assemble_state +
                    "-FA(Unassigned)";
                  const startdate1 = items.start_date;
                  const time = items.start_time;
                  var dateTime13 = startdate1 + " " + time;
                  const start = dateTime13;
                  const color = "#A9A9A9";
                   const eventids="3"
                  const id = items.assemble_id;
                  this.events.push({ name, start, color,eventids, id });
                }
              }
            }
          }
          if (items.event_category == 6) {
            for (var p = 0; p < this.eventss.length; p++) {
              for (const itemspaid of this.eventss[p].assemblies) {
                if (items.assemble_id == itemspaid.id) {
                  if (
                    itemspaid.assemble_status == "paid" &&
                    itemspaid.team != null &&
                    itemspaid.player_list == null
                  ) {
                    const name =
                      itemspaid.city +
                      "," +
                      itemspaid.assemble_state_paid +
                      "- PA(" +
                      itemspaid.team_name +
                      " )";
                    const startdatepaid = items.start_date;
                    const time = items.start_time;
                    var dateTimepaid = startdatepaid + " " + time;
                    const start = dateTimepaid;
                    const color = "#edc967a1";
                    const id = itemspaid.id;
                     const eventids="3"
                    this.events.push({ name, start, color,eventids, id });
                  }

                  if (
                    itemspaid.assemble_status == "paid" &&
                    itemspaid.team == null &&
                    itemspaid.player_list != null
                  ) {
                    const name =
                      itemspaid.city.toUpperCase() +
                      "," +
                      itemspaid.assemble_state_paid +
                      "- PA(" +
                      itemspaid.player_list.map((player) => player.name)+
                      " )";
                    const startdatepaid = items.start_date;
                    const time = items.start_time;
                    var dateTimepaid1 = startdatepaid + " " + time;
                    const start = dateTimepaid1;
                    const color = "#edc967a1";
                    const id = itemspaid.id;
                     const eventids="3"
                    this.events.push({ name, start, color,eventids, id });
                  }

                  if (
                    itemspaid.assemble_status == "paid" &&
                    itemspaid.team == null &&
                    itemspaid.player_list == null
                  ) {
                    const name =
                      itemspaid.city.toUpperCase() +
                      "," +
                      itemspaid.assemble_state_paid +
                      "- PA(Unassigned)";
                    const startdatepaid = items.start_date;
                    const time = items.start_time;
                    var dateTimepaid2 = startdatepaid + " " + time;
                    const start = dateTimepaid2;
                    const color = "#A9A9A9";
                     const eventids="3"
                    const id = itemspaid.id;
                    this.events.push({ name, start, color,eventids, id });
                  }
                }
              }
            }
          }
          for (var b = 0; b < this.eventss.length; b++) {
            for (const itemsasemh of this.eventss[b].hotel_detail) {
              if (items.hotel_id == itemsasemh.id) {
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name != null &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.hotel_city +
                    "," +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.team_name +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                   const eventids="4"
                  const id = items.hotel_id;
                  this.events.push({ name, start, color,eventids, id });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list != null
                ) {
                  const name =
                    itemsasemh.hotel_city +
                    "," +
                    itemsasemh.hotel_state +
                    " - HOTEL(" +
                    itemsasemh.player_list.map((player) => player.name) +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                   const eventids="4"
                  const id = items.hotel_id;
                  this.events.push({ name, start, color,eventids, id });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game != null &&
                  itemsasemh.team_name == null &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.hotel_city +
                    "," +
                    itemsasemh.hotel_state +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const color = "#A9A9A9";
                   const eventids="4"
                  const id = items.hotel_id;
                  this.events.push({ name, start, color,eventids, id });
                }

                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team != null &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city +
                    "," +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.team +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                  const id = items.hotel_id;
                  this.events.push({ name, start, color, id });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team == null &&
                  itemsasemh.player_list != null
                ) {
                  const name =
                    itemsasemh.city +
                    "," +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(" +
                    itemsasemh.player_list.map((player) => player.name) +
                    ")";
                  const start = items.start_date;
                  const color = "#E56E94 ";
                   const eventids="4"
                  const id = items.hotel_id;
                  this.events.push({ name, start, color, eventids,id });
                }
                if (
                  items.event_category == 4 &&
                  items.event_type == "hotel" &&
                  itemsasemh.game == null &&
                  itemsasemh.team == null &&
                  itemsasemh.player_list == null
                ) {
                  const name =
                    itemsasemh.city +
                    "," +
                    itemsasemh.hotel_state_paid +
                    " - HOTEL(Unassigned)";
                  const start = items.start_date;
                  const color = "#A9A9A9";
                   const eventids="4"
                  const id = items.hotel_id;
                  this.events.push({ name, start, color, eventids,id });
                }
              }
            }
          }
          if (items.event_category == 5) {
            const name = items.task_title;
            const start = items.start_date;
            const end = items.end_date;
            const color = "#F9B4F6";
            const id = items.id;
            this.events.push({ name, start, end, color, id });
          }
          if (items.event_category == 7) {
            const name = "PTO-(" + items.player_name + ")";
            const start = items.start_date;
            const end = items.end_date;
            const color = "#a3c585";
            this.events.push({ name, start, end, color });
          }
          for (var i = 0; i < this.eventss.length; i++) {
            for (const itemgame of this.eventss[i].games) {
              if (items.game_id == itemgame.id) {
                if (
                  itemgame.team_name == null &&
                  itemgame.player_list != null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    "," +
                    itemgame.state_name +
                    " " +
                    " - GAME " +
                    "(" +
                    itemgame.player_list.map((player) => player.name)+
                    ")";
                  const startdate = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame = startdate + " " + time;
                  const start = dateTimegame;
                  const endtime = itemgame.end_time;
                  var enddateTimegame = startdate + " " + endtime;
                  const end = enddateTimegame;
                  const color = "#cb3b3878";
                   const eventids="1"
                  const id = itemgame.id;
                  this.events.push({ name, start, end, color,eventids, id });
                }
                if (
                  itemgame.team_name != null &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    "," +
                    itemgame.state_name +
                    " " +
                    " - GAME " +
                    "(" +
                    itemgame.team_name +
                    ")";
                  const startdate = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame1 = startdate + " " + time;
                  const start = dateTimegame1;
                  const endtime = itemgame.end_time;
                  var enddateTimegame1 = startdate + " " + endtime;
                  const end = enddateTimegame1;
                  const color = "#cb3b3878";
                  const eventids="1"
                  const id = itemgame.id;
                  this.events.push({ name, start, end, color,eventids, id });
                }
                if (
                  itemgame.team_name == null &&
                  itemgame.player_list == null
                ) {
                  const name =
                    itemgame.city.toUpperCase() +
                    "," +
                    itemgame.state_name +
                    " " +
                    " - GAME(Unassigned)";
                  const startdate = itemgame.date;
                  const time = itemgame.start_time;
                  // conversion
                  var dateTimegame2 = startdate + " " + time;
                  const start = dateTimegame2;
                  const endtime = itemgame.end_time;
                  var enddateTimegame2 = startdate + " " + endtime;
                  const end = enddateTimegame2;
                  const color = "#A9A9A9";
                  const eventids="1"
                  const id = itemgame.id;
                  this.events.push({ name, start, end, color, eventids,id });
                }
              }
            }
          }
        }
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      let formData = {};
      // alert(this.selectGameData.id)
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_CALENDAR_API,

        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    dateChange(date) {
      console.log(date);
    },
    setToday() {
      this.focus = "";
    },
   showEvent({ nativeEvent, event }) {
   //alert("in event")
      const open = () => {
        this.selectedEvent = event;
       // alert(this.selectedEvent.color);
        this.chekinclude = this.selectedEvent.name;
        // alert(this.chekinclude.includes("KO"));
        // alert(this.selectedEvent.id);
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
      if (this.selectedEvent.eventids == 1) {
        // alert(this.selectedEvent.eventids);
        this.getGameDetail();
      }
      if (this.selectedEvent.eventids == 3) {
        this.getAssemblyDetail();
      }
      if (this.selectedEvent.color == "#edc967a1") {
        // alert(this.selectedEvent.id);
        this.getAssemblyDetail();
      }
      if (this.selectedEvent.eventids == 4) {
     // alert("in hotel")
        // alert(this.selectedEvent.id);
        this.getHotelDetail();
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
   getGameDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.gameData = res.data.game_detail;
        if (this.gameData.ticket_data != null) {
          this.tickettypeitems = this.gameData.ticket_data;
        }
        if (this.gameData.hotel_data != null) {
          this.gamehotalData = this.gameData.hotel_data[0];
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.selectedEvent.id;
      Axios.request_GET(
        ADMIN_GAMES_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getAssemblyDetail() {
      const successHandler = (res) => {
        console.log(res);
        this.school = res.data.assemble_detail.school;
        this.address = res.data.assemble_detail.address;
        this.date = res.data.assemble_detail.date;
        this.start_time = res.data.assemble_detail.start_time_str;
        this.end_time = res.data.assemble_detail.end_time_str;
        this.noOfStudents = res.data.assemble_detail.total_student;
        this.noOfAssemblies = res.data.assemble_detail.total_assemblies;
        this.name = res.data.assemble_detail.driver_name;
        this.mobile = res.data.assemble_detail.phone;
        this.email = res.data.assemble_detail.email;
        this.notes = res.data.assemble_detail.note;
        this.loading = false;

        this.assemblyData = res.data.assemble_detail;
        this.assemblegameNotes=this.assemblyData.game_notes;
        if (this.assemblyData.ticket_data != null) {
          this.ticketassemblyitems = this.assemblyData.ticket_data;
        }
        console.log("this.ticketassemblyitems", this.ticketassemblyitems);
        if (this.assemblyData.player_list != null) {
          this.playeritems = this.assemblyData.player_list;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["assemble_id"] = this.selectedEvent.id;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getHotelDetail() {
      const successHandler = (res) => {
        console.log(res);
        this.loading = false;
        this.hotelData = res.data.hotel_detail;
        var hoteldisplaydatess = new Date(this.hotelData.checking_in_date);
        var datesssh = new Date(hoteldisplaydatess);
        this.hoteldisplaydatess = datesssh.toDateString();
        if (this.hotelData.player_list != null) {
          this.hotelplayeritems = this.hotelData.player_list;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["hotel_id"] = this.selectedEvent.id;
      Axios.request_GET(
        GAME_HOTEL_GET_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
     converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
  },
  computed: {
    ...mapGetters({
      selectGameData: "gameManagement/getGame",
    }),
    selectedEventStart() {
      return this.selectedEvent && this.selectedEvent.start;
    },
    selectedEventEnd() {
      return this.selectedEvent && this.selectedEvent.end;
    },
  },
};
</script>
