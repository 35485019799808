<template>
  <div>
    <manual-email-game-table v-if="isTable"></manual-email-game-table>
    <manual-email-game-form v-else></manual-email-game-form>
  </div>
</template>
<script>
export default {
  name: "CampaignPanelEmail",
  components: {
    ManualEmailGameTable: () => import("../BulkGameEmails"),
    ManualEmailGameForm: () => import("../BulkEmailForm/BulkEmailGameForm"),
  },
  data() {
    return {
      isTable: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    changeTab(isTable) {
      this.isTable = isTable;
    },
  },
  mounted() {},
  created() {
    this.$root.$refs.bulkGameEmail = this;
  },
};
</script>
